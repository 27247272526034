import React, { useState } from "react"
import HorizontalTimeline from "react-horizontal-timeline"
import SwipeableViews from "react-swipeable-views"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export const Timeline = ({ data }) => {
  const [value, setValue] = useState(0)
  const [previous, setprevious] = useState(0)

  const dates = data.map(moment => moment.node.date)
  const moments = data.map((moment, index) => {
    return (
      <div className="container" key={index}>
        <h1>{moment.node.title}</h1>
        {documentToReactComponents(moment.node.description.json)}
      </div>
    )
  })

  return (
    <>
      <div style={{ width: "60%", height: "100px", margin: "0 auto" }}>
        <HorizontalTimeline
          index={value}
          indexClick={index => {
            setValue(index)
            setprevious(value)
          }}
          values={dates}
        />
      </div>

      <div className="content">
        <SwipeableViews
          index={value}
          onChangeIndex={(value, previous) => {
            setValue(value)
            setprevious(previous)
          }}
          resistance
        >
          {moments}
        </SwipeableViews>
      </div>
    </>
  )
}
