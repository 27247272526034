import React from "react"
import { graphql } from "gatsby"

import "../css/index.css"
import Layout from "../components/layout"
import { Timeline } from "../components/timeline"

const IndexPage = ({
  data: {
    allContentfulSteppingStone: { edges },
  },
}) => {
  return (
    <Layout>
      <Timeline data={edges} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    allContentfulSteppingStone(sort: { fields: [date], order: ASC }) {
      edges {
        node {
          id
          date
          title
          description {
            json
          }
        }
      }
    }
  }
`
